import { SiteFooter } from "./footer.styles";
import Link from "next/link";
import { P } from "@components/common/text";
import styled from "styled-components";

const Footerlink = styled(P)`
  margin-top: 0;
  margin-bottom: 0.25rem;
  &:last-child {
    margin-top: 0.5rem;
  }
`;

export default function Footer() {
  return (
    <SiteFooter>
      <nav>
        <Footerlink small>
          <Link href="/terms-and-conditions">
            <a>Site Terms and Conditions</a>
          </Link>
        </Footerlink>
        <Footerlink small>
          <Link href="/privacy-policy">
            <a>Data and Privacy Policy</a>
          </Link>
        </Footerlink>
        <Footerlink small>
          <Link href="/street-rep-terms">
            <a>Street Rep Terms and Conditions</a>
          </Link>
        </Footerlink>
        <Footerlink small>
          RentCheck is an initiative of the{" "}
          <a
            href="https://eastendtradesguild.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            East End Trades Guild
          </a>
        </Footerlink>
      </nav>
    </SiteFooter>
  );
}
