import { Layout, PageHeader } from "antd";
import { useRouter } from "next/router";
import Footer from "@components/Footer";

const { Content } = Layout;

export default function PageLayout({
  title,
  subTitle,
  children,
  MenuComponent,
  showBack,
  noMargins,
}) {
  const router = useRouter();
  const classes = `pa1 pa3-m pa4-l f5 f4-l measure-wide center`;
  const classesNoMargins = ``;
  const handleBack = typeof showBack === "function" ? showBack : router.back;
  const titleHeight = 67;
  const navHeight = 56;
  const footerHeight = 165;
  const padding = -16;
  const offsetHeight =
    navHeight +
    footerHeight +
    padding +
    (title ? titleHeight : 0) +
    (MenuComponent ? navHeight : 0);
  return (
    <Layout>
      {title && (
        <PageHeader
          style={{
            color: "var(--white)",
            background: "rgba(14, 71, 98, 0.25)",
          }}
          onBack={showBack ? handleBack : false}
          title={title}
          subTitle={subTitle}
        />
      )}
      {MenuComponent && <MenuComponent />}
      <Content
        style={{
          minHeight: `calc(100vh - ${offsetHeight / 16}rem)`,
          padding: "0.5rem",
        }}
      >
        <div className={noMargins ? classesNoMargins : classes}>{children}</div>
      </Content>
      <Footer />
    </Layout>
  );
}
