import styled from "styled-components";

export const SiteFooter = styled.footer.attrs({
  className: "bg-lightest-blue",
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem 1rem 0.5rem 1rem;
  font-size: 1rem;
`;
