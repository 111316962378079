import styled from "styled-components";

export const P = styled.p.attrs({
  className: "lh-copy measure-wide f5 f4-l",
})`
  width: 100%;
  ${props => (props.center ? `text-align: center;` : undefined)}
  ${props => (props.right ? `text-align: right;` : undefined)}
  ${props => (props.wrap ? `word-wrap: break-word;` : undefined)}
  ${props =>
    props.truncate
      ? `max-width: 100%;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
   `
      : undefined}
   ${props => (props.small ? `font-size: 1rem !important;` : undefined)}
   ${props => (props.narrow ? `max-width: 30em !important;` : undefined)}
`;

export const H1 = styled.h1.attrs({
  className: "f3 f2-ns",
})``;

export const H2 = styled.h2``;

export const H3 = styled.h3``;

export const H4 = styled.h4``;

export const Label = styled.span.attrs({ className: "fw5 db dib-ns mr1" })``;
